import React from "react"
import { Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Layout from "../components/layout"
import "../components/layout.css"
import SEO from "../components/seo"
import logo from "../images/360logo2.png"
import competencies from "../images/GLA-Competency-Descriptions.pdf"
import values from "../images/BKM_Personal_Values.pdf"
import leadership from "../images/Brittany_Macdonald_Authentic_Leadership_Model.pdf"
import leadervest from "../images/Leadervest.png"
import support from "../images/support.png"

const LeaderVest = () => (
  <Layout>
    <SEO
      title="Leadership Training Program – Certified Leadership Coach"
      description="At AGL Coaching For Good we offer expert leadership training programs to help you better guide your company. We are certified leadership coaches, so trust we have your back!"
    />
    <div className="page-headline">
      <div className="container ">
        <div className="section-heading text-center">
          <h1>
            <strong>LeaderVest – Leadership Training Program</strong>
          </h1>
        </div>
        <div className="my-5 text-center">
          <Link to="/#get-coaching">
            <span className="hero-btn2 align-items-center sched-modal">
              Get Coaching
            </span>
          </Link>
        </div>
      </div>
      <div className="headline-bg" />
    </div>
    <div className="page-content">
      <div className="container">
        <div className="row justify-content-between pt-5">
          <div className="col-md-3 mx-auto mb-5">
            <LazyLoadImage
              className="engager-icon mr-3 img-fluid"
              src={leadervest}
              alt="Engager Icon"
            />
          </div>
          <div className="col-md-8 mx-auto">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <h5 className="mb-0">
                    <strong>Hi! We are Eddy and Brittany. </strong>{" "}
                  </h5>
                  <p className="">
                    We spent years working at shitty companies, feeling
                    frustrated by leaders who didn’t “get it” or frustrated with
                    ourselves for our own seeming inability to be heard and make
                    a difference.
                  </p>
                  <p className="">
                    Then we decided to start our own company. After all, if the
                    places we worked for couldn’t get it right, surely we could
                    learn from their mistakes and do better -- right?
                  </p>
                  <p className="">
                    As it turns out, it was hard. Far harder than we could have
                    possibly imagined.
                  </p>
                  <p className="">
                    Building a business is hard. Sticking with it month to month
                    in the startup stage is hard. Learning to communicate about
                    how we communicate is hard. Working through challenging
                    situations with clients and team members is hard. Finding
                    clients is hard. Learning how to lead authentically and
                    effectively is hard.
                  </p>
                  <p className="">
                    LeaderVest is born out of this place of struggle. It’s our
                    response to the challenges we’ve seen and experienced in the
                    workplace, in nonprofits, and in our own leadership
                    capacities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="page-content leadervest-bg">
      <div className="container">
        <div className="row justify-content-between pt-5 p-2">
          <div className="col-md-6 mx-auto">
            <h2 className="mb-0	text-white">
              <strong>
                LeaderVest is a leadership training program that is about three
                things.
              </strong>{" "}
            </h2>
            <ol className=" text-left text-white">
              <li className=" my-3">
                It’s about INVESTing. Invest in yourself. Invest in your
                leadership potential. Invest in your team and your organization.
                Things don’t grow without investment, and nothing invested is
                ever wasted.
              </li>
              <li className=" my-3">
                It’s about VESTing. The word “vest” means to confer or bestow
                authority. Sometimes, we are just looking for someone outside of
                ourselves to give us a little validation. To let us know that
                we’re on the right track, and give us an idea of what to do
                next.
              </li>
              <li className=" my-3">
                It’s about LEADERvesting. Not just investing some dollars in
                training. Not just receiving someone else’s validation. It’s
                about finding the leader in yourself, calling out your own
                authority and power, understanding your unique values and
                knowing how they show up in everything you do. It’s choosing to
                claim, confer and bestow your own authority for yourself and
                this leadership training program does all the above and more.
              </li>
            </ol>
          </div>
          <div className="col-md-6 mx-auto"></div>
        </div>
      </div>
    </div>
    <div className="page-content">
      {/*<div className="col-md-3 mx-auto">
						<LazyLoadImage
							className="engager-icon mr-3 img-fluid"
							src={support}
							alt="Engager Icon"
						/>
					</div>*/}

      <div className="container">
        <div className="row justify-content-between pt-5">
          <div className="col-md-6">
            <h2 className="mb-0 text-gold">
              <strong>Our Story</strong>{" "}
            </h2>
            <p className="">
              When we started Coaching for Good in 2018, we had completed
              trainings, hired coaches, gone to conferences, taken classes,
              pursued education, and earned certifications to become certified
              leadership coaches.
            </p>
            <p className="">
              We spent time with thought leaders, honed our values, and became
              comfortable with the idea of risk. We felt ready to take it to the
              next level.
            </p>
            <p className="">
              Then we got there. And the next level wasn’t anything like what we
              planned.
            </p>
            <p className="">
              We had so many people “vesting” their insight, wisdom, and power
              in us through our leadership training program. But instead of
              taking that perspective and building on it, we got addicted. We
              kept seeking more and more validation. We needed others to tell us
              we were good enough, smart enough, trained enough, certified
              enough.
            </p>
            <p className="">
              Along the way, we started to learn the hard way that we are the
              only ones who can own our leadership journey.
            </p>
          </div>
          <div className="col-md-6">
            <p className="">
              So we dug deeper. We found our insecurities and faced them
              head-on. We looked at our shadow and made friends with it. We
              stopped blaming others for the obstacles we faced and took
              ownership of where we were. We dug - really dug - into our
              strengths and values and how to live them out authentically.
            </p>
            <p className="">Which leads us to today.</p>
            <p className="">
              We are still learning. Still finding our authentic voice.
              Clarifying the messages we WANT to share, not the ones we think
              will bring in the most money.
            </p>
            <p className="">
              We are finding the clients who want to go to the next level; not
              just increase the bottom line or check a training box. The ones
              who are passionate, who want something more, who have grown and
              grown and still wonder what it’s going to take to feel confident
              in their leadership.
            </p>
            <p className="">
              We don’t have it all figured out. But we are learning. We are
              growing. And we have helped some people along the way.
            </p>
            <p className="">
              If you’re interested in leadership training programs by certified
              leadership coaches, we would love the honor to help you too.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="page-content leadership-bg">
      <div className="container">
        <div className="row justify-content-between pt-5 p-2">
          <div className="col-md-6 mx-auto">
            <h2 className="mb-0	text-white">
              <strong>What We Offer </strong>{" "}
            </h2>
            <p className="text-white ">
              LeaderVest is a highly customizable leadership investment training
              program designed to take you inward.
            </p>
            <p className="text-white ">
              The journey to authentic leadership and living doesn’t happen by
              pushing to the next level, hitting the next benchmark, or
              spreading yourself too thin.
            </p>
            <p className="text-white ">
              It comes by diving deep, uncovering hidden truths, and learning to
              stand in your own strength. It comes by walking alongside other
              people, and learning from them as you grow.
            </p>
            <p className="text-white mb-5 ">
              When we talk, we will explore the areas that feel “not quite
              there” or “not enough.” Then we will design a custom leadership
              training program to take you and your team to that deeper level.
              We will choose from the following options to maximize your
              investment and your potential:
            </p>
            <Link to="/contact-us">
              <span className="schedule-btn2 top-btn-sched mr-3 sched-modal mt-5">
                &nbsp;Contact Us
              </span>
            </Link>
          </div>
          <div className="col-md-6 mx-auto"></div>
        </div>
      </div>
    </div>
    <div className="page-content ">
      <div className="container">
        <div className="row justify-content-between pt-5">
          <div className="col-md-10 mx-auto">
            <div className="text-center">
              <h2 className="mb-0 ">
                <strong>Leadership Investment</strong>{" "}
              </h2>
              <p className=" ">
                Growth starts with you. To begin to see impactful growth and
                change in yourself and your company, we will determine the key
                components YOU need to get started:
              </p>
              <p className="text-left">
                <ul className="checks-ul mt-5">
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Coaching:</strong>&nbsp; meet with a coach every
                      two weeks for an entire year.
                    </p>
                  </li>
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Goals:</strong>&nbsp;individualize and specify the
                      growth you want for yourself and your organization.
                    </p>
                  </li>
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Values:</strong>&nbsp;explore and define your core
                      self.
                    </p>
                  </li>
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Strengths:</strong>&nbsp;assessments and support
                      to apply your key skills at work.
                    </p>
                  </li>
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Neuroscience:</strong>&nbsp;brain-based training
                      to understand and work with (not against) your brain.
                    </p>
                  </li>
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Emotional IQ:</strong>&nbsp;learn to make your
                      emotions work for you rather than working for your
                      emotions.
                    </p>
                  </li>
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Brain Training:</strong>&nbsp;master your thoughts
                      and rewire your brain to maximize your potential.
                    </p>
                  </li>
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Recommended Reading:</strong>&nbsp; explore
                      cutting-edge resources in psychology, leadership
                      development, and neuroscience.
                    </p>
                  </li>
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Service:</strong>&nbsp; grow your perspectives and
                      your empathy by participating in ways to give back to
                      those around you.
                    </p>
                  </li>
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Adventure:</strong>&nbsp; get outside of your
                      comfort zone and push yourself mentally and physically.
                    </p>
                  </li>
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Action:</strong>&nbsp;co-design customized action
                      steps to facilitate your personal growth.
                    </p>
                  </li>
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Intercultural Development:</strong>&nbsp;dig into
                      your underlying assumptions and perspectives about those
                      around you, and work to grow them.
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-between pt-5">
          <div className="col-md-10 mx-auto">
            <div className="text-center">
              <h2 className="mb-0 ">
                <strong>Team Culture</strong>{" "}
              </h2>
              <p className=" ">
                Growth doesn’t happen in a bubble. We bring the same concepts to
                your team to facilitate a common language, accelerate your
                growth, and build your organization.
              </p>
              <p className="text-left">
                <ul className="checks-ul mt-5">
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Culture Pulse:</strong>&nbsp; build a better team
                      in two days through a communication & team-building
                      retreat.
                    </p>
                  </li>
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Social Impact Strategies:</strong>&nbsp; learn to
                      intentionally pursue purpose along with profit.
                    </p>
                  </li>
                  <li className="list-checks">
                    <p className=" ">
                      <strong>StratOp:</strong>&nbsp;apply purpose and values to
                      a three day strategic planning session used by
                      organizations like Otterbox.
                    </p>
                  </li>
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Interculture Development Inventory:</strong>
                      &nbsp;grow your teams in diversity and inclusion through
                      assessments and training.
                    </p>
                  </li>
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Amplify Your Voice:</strong>&nbsp;instill
                      confidence and clarity in communicating your message.
                    </p>
                  </li>
                  <li className="list-checks">
                    <p className=" ">
                      <strong>Celebration Retreat:</strong>&nbsp; bring your
                      team together in an exotic environment to celebrate an
                      incredible year of purpose, passion, and growth.
                    </p>
                  </li>
                </ul>
                <div className="my-5 text-center">
                  <Link to="/#get-coaching">
                    <span className="hero-btn align-items-center sched-modal">
                      Get Coaching
                    </span>
                  </Link>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default LeaderVest
